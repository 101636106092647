import react, {useEffect} from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import SignUp from '../../Auth/SignUp'
import ConfirmSignUp from '../../Auth/ConfirmSignUp'
import Login from '../../Auth/Login'
import ForgotPassword from '../../Auth/ForgotPassword'
import ResetPassword from '../../Auth/ResetPassword'
import { AuthProvider } from '../../Auth/AuthContext'
import {RouteGuard, RoleGuard} from '../../RouteGuard'

import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import AboutPage from '../../pages/AboutPage'
import NotFoundPage from '../../pages/NotFoundPage'
import AchievementApprovalPage from '../../pages/AchievementApprovalPage'
import AllAchievementsPage from '../../pages/AllAchievementsPage'
import ProfilePage from '../../pages/ProfilePage'
import AICoachPage from '../../pages/AICoachPage'
import TestPage from '../../pages/TestPage'

function App() {

  useEffect(() => {
    document.title = 'PTS Pocket';
  }, []);

  return (
  <AuthProvider>

    <BrowserRouter>
      <div className="min-h-screen max-w-[100%] flex flex-col m-0 p-0">
      
        <div className="sticky top-0 z-40">
          <Header />
        </div>
       
        <div className=" flex-1 bg-PTSBackgroundMid">
          <Routes>
            {/* Protected Routes */}
            <Route path="/achievementapproval" element={<RouteGuard><RoleGuard><AchievementApprovalPage /></RoleGuard></RouteGuard>}/>
            <Route path="/test" element={<RouteGuard><TestPage /></RouteGuard>}/>
                                    
            {/* Unprotected */}
            <Route path="/" element={<RouteGuard><ProfilePage /></RouteGuard>}/>
            <Route path="/achievements" element={<RouteGuard><AllAchievementsPage /></RouteGuard>}/>
            <Route path='/about' element={<AboutPage/>} />
            <Route path="/aicoach" element={<RouteGuard><AICoachPage /></RouteGuard>}/>
                      
            {/*Auth Pages*/}
            <Route path='/signUp' element={<SignUp/>} />
            <Route path="/confirmsignup" element={<ConfirmSignUp/>} />
            <Route path="/login" element={<Login />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />

            {/* Default */}
            <Route path='/*' element={<NotFoundPage />} />
          </Routes>
        </div>
       
        <div className="sticky bottom-0 z-40">
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  </AuthProvider>
  )
}

export default App