import React from 'react';

function AboutPage() {

    return (
        <div className='overflow-auto h-full'>
          <>
            <h1 className='text-9xl	'>TESTING SPACE</h1>
          </>    
        </div>
    );
}

export default AboutPage