

function SpinningLoader() {
  


  return (   
    <div className="m-auto text-PTSBackgroundLight">
      <div className="border-white h-20 w-20 animate-spin rounded-full border-8 border-t-PTSAmber">
      </div>
        Loading......
    </div>
    )
}

export default SpinningLoader