import React from 'react';
import { Switch } from '@headlessui/react'
import { useEffect, useState, useContext } from 'react';

import AchievementGridV2 from '../components/Grids/AchievementGridV2';
import Modal from '../components/Modal/Modal';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Collapsible from 'react-collapsible';
import { AuthContext } from '../Auth/AuthContext';

import Accordian, {AccordianItem } from '../components/Accordian/Accordian';
import SpinningLoader from '../components/Loading/SpinningLoader';
import { formatDate } from '../functionality/dateFunctions';
import { SubmitButton } from 'react-dropzone-uploader';
import AchievementApproval from './AchievementApprovalPage';

function AllAchievementsPage(){

  const {user} = useContext(AuthContext)

  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [hideComplete, setHideComplete] = useState(false);
  const [searchString, setSearchString] = useState('');

  function SetSelection(GroupID, ActivityID)
  {
    setSelectedGroup(GroupID);
    setSelectedActivity(ActivityID);
    // console.log(`onClick Set Params: ${GroupID} | ${ActivityID}`)
  }

  //Check if everything has been closed
  const handleFilterClose = event => {
    // console.log(`Accordian Open: ${event}`)
    if(event === null)
    {
      SetSelection(-1,-1)
    }
  }

  const handleSearchStringchange = event => {
    setSearchString(event.target.value);
  }

  //Upload Modal
  const [showModal, setShowModal] = useState(false)
  const [selectedAchievement, setSelectedAchievement] = useState(-1)
  const [selectedActivity, setSelectedActivity] = useState(-1)
  const [selectedGroup, setSelectedGroup] = useState(-1)

  const handleOnClose = (achievement,submitted) => {
    setShowModal(false);

    // console.log(`Params: ${JSON.stringify(achievement)} | ${submitted}`)

    if(submitted === true){

      for (const group of filteredItems) {
        for (const activity of group.Activities) {
          for (const ach of activity.Achievements) {
            if (ach.AchievementID === achievement.AchievementID) {
              ach.UserAchievement = [{"ApprovalStatusID":0, "DateAchieved":new Date()}];
            }
          }
        }
      }
    }
  }

  const [enabled, setEnabled] = useState(false)

  //So we know to show the modal and which achievement was clicked
  function SubmitCallBack(achievement){
    // console.log(`Callback Hit: ${achievement}`)
    setShowModal(true);
    setSelectedAchievement(achievement);


    // console.log(`filtered: ${JSON.stringify(filteredItems)}`);
    // console.log(`current: ${JSON.stringify(achievement)}`)
    // for (const group of filteredItems) {
    //   for (const activity of group.Activities) {
    //     for (const ach of activity.Achievements) {
    //       if (ach.AchievementID === achievement.AchievementID) {
    //         ach.UserAchievement = [{"ApprovalStatusID":0, "DateAchieved":new Date()}];
    //       }
    //     }
    //   }
    // }

  }

  const HandleUserRejectClick = async(achievement) =>{
    // console.log(`test_-: ${JSON.stringify(achievement)}`)
    const res = await toast.promise(
      fetch('https://40338odobj.execute-api.eu-west-2.amazonaws.com/testing/achievements/approval',{
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json",
          "Token": user.usertoken
        },      
        body: JSON.stringify(achievement)
      }),{
        pending: 'Acknowledging...',
        success: 'Acknowledged!',
        error: 'Error!'
      }
    );
  
    //Update the page content
    getAchievements();

    // SetSelection(selectedGroup,selectedActivity)
  }

  const handleUserApproveClick = async(achievement) => {
  
    // console.log(`Handle Approve: ${JSON.stringify(achievement)}`);
    console.log(`User Appro: ${JSON.stringify(achievement)}`)

    const res = await toast.promise(
      fetch('https://40338odobj.execute-api.eu-west-2.amazonaws.com/testing/achievements/approval',{
        method: 'PATCH',
        headers: {
          "Content-Type": "application/json",
          "Token": user.usertoken
        },      
        body: JSON.stringify(achievement)
      }),{
        pending: 'Acknowledging...',
        success: 'Acknowledged!',
        error: 'Error!'
      }
    );

    //Update the page content
    getAchievements();
  }


  //Define the function for getting the users achievements 
  const getAchievements = async ()=>{
    setIsLoading(true);
    console.log(user.username);
    const res = await fetch(`https://40338odobj.execute-api.eu-west-2.amazonaws.com/testing/achievements?id=${user.username}`,{
      method: 'GET',
      headers:{
        'Token': `${user.usertoken}`
      }});
    
    const data = await res.json();
    console.log(`Data Incoming: ${JSON.stringify(data)}`);
    setItems(formatData(data));
    // {console.log(`Data Outgoing: ${JSON.stringify(items)}`)}
    setIsLoading(false);
  };
  
  //Get achievements from the API
  useEffect(()=>{
    getAchievements();
  },[]);
   
  //Whenever selection, hidecomplete or search string change. Refilter data.
  useEffect(()=>{ 
    setFilteredItems(filterActivitiesByActivityID())
  },[items,selectedGroup, selectedActivity, hideComplete, searchString])

function formatData(data){

  // console.log("Achievement Data In: ", data);
  const result = data.Groups.map(group => {
    return {
        ...group,
        Activities: data.Activities
            // .filter(activity => activity.GroupID === group.GroupID && (activity.ActivityID === selectedActivity || selectedActivity === -1))
            .filter(activity => activity.GroupID === group.GroupID)
            .map(activity => {
                return {
                    ...activity,
                    Achievements: data.Achievements.filter(achievement => achievement.ActivityID === activity.ActivityID)
                    .map(achievement => {
                        return{
                          ...achievement,
                          UserAchievement: data.UserAchievements.filter(userAchievement => userAchievement.AchievementID == achievement.AchievementID)
                        }                     
                    })
                };
            })
    };
  });

  // console.log("Achievement Data Out: " + JSON.stringify(result, null, 2));

  return result;
}

function filterActivitiesByActivityID() {

  // console.log(`Data Test: ${JSON.stringify(items)}`)
  //Filters data by
  const filteredData = items.filter(group=> group.GroupID === selectedGroup || selectedGroup === -1).map(group => {
      return {
        ...group,
        Activities: group.Activities.filter(activity => activity.ActivityID === selectedActivity || selectedActivity === -1)
        .map(activity =>({
          ...activity,
          Achievements: activity.Achievements.filter(achievement => (achievement.UserAchievement.length === 0 || hideComplete === false) && (achievement.Name.toLowerCase().includes(searchString.toLowerCase())))
        })).filter(activity => activity.Achievements.length > 0)
      };
  }).filter(group => group.Activities.length > 0);
  
  //.filter(activity => activity.Achievements.length > 0);

  return filteredData;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

  return (
    

    //All achievements are always a grid of achievements, selector on the left and achievements on the right
    <div className='grid grid-cols-3 bg-PTSBackgroundMid
      sm:grid-cols-6
      lg:grid-cols-12'> 

      {/* Achievement Filters */}
      <div className='col-start-1 col-span-full'> 

        <div>
        <Modal onClose={handleOnClose} visible={showModal} achievement={selectedAchievement}/>
                  <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    />
        </div>
      
        {
          isLoading === false ? (
            <AchievementGridV2 onSubmit={SubmitCallBack} onUserRejectClick={HandleUserRejectClick} onUserApproveClick={handleUserApproveClick} data={items}></AchievementGridV2>
          ) : (
            <div className='flex p-5'>
              <SpinningLoader/>
            </div>
          )
        }
      </div>
    </div>
  );
}

export default AllAchievementsPage