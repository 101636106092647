import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
  } from "amazon-cognito-identity-js"
  import { cognitoConfig } from "../cognitoConfig"
  
  const userPool = new CognitoUserPool({
    UserPoolId: cognitoConfig.UserPoolId,
    ClientId: cognitoConfig.ClientId,
  })
  
  export function signUp(username, name, email, password) {
    // Sign up implementation
    return new Promise((resolve, reject) => {
        userPool.signUp(
          username,
          password,
          [{ Name: "email", Value: email },
           { Name: "name", Value: name},
          ],
          null,
          (err, result) => {
            if (err) {
              reject(err)
              return
            }
            resolve(result.user)
          }
        )
      })
  }
  
  export function confirmSignUp(username, code) {
    // Confirm sign up implementation
    return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
          Username: username,
          Pool: userPool,
        })
    
        cognitoUser.confirmRegistration(code, true, (err, result) => {
          if (err) {
            reject(err)
            return
          }
          resolve(result)
        })
      })
  }
  
  export function signIn(username, password) {
    // Sign in implementation
    return new Promise((resolve, reject) => {
        const authenticationDetails = new AuthenticationDetails({
          Username: username,
          Password: password,
        })
    
        const cognitoUser = new CognitoUser({
          Username: username,
          Pool: userPool,
        })
    
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: (result) => {
            // console.log("login result: ",result);
            resolve(result)
          },
          onFailure: (err) => {
            reject(err)
          },
        })
      })
  }
  
  export function forgotPassword(username) {
    // Forgot password implementation
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      })
  
      cognitoUser.forgotPassword({
        onSuccess: () => {
          resolve()
        },
        onFailure: (err) => {
          reject(err)
        },
      })
    })
  }
  
  export function confirmPassword(username, confirmationCode, newPassword) {
    // Confirm password implementation
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      })
  
      cognitoUser.confirmPassword(confirmationCode, newPassword, {
        onSuccess: () => {
          resolve()
        },
        onFailure: (err) => {
          reject(err)
        },
      })
    })
  }
  
  export function signOut() {
    // Sign out implementation
    const cognitoUser = userPool.getCurrentUser()
    if (cognitoUser) {
      cognitoUser.signOut()
    }
  }
  
  export function getCurrentUser() {
    // Get current user implementation
    return new Promise((resolve, reject) => {
        const cognitoUser = userPool.getCurrentUser()
    
        if (!cognitoUser) {
          reject(new Error("No user found"))
          return
        }
    
        cognitoUser.getSession((err, session) => {
          if (err) {
            reject(err)
            return
          }
          cognitoUser.getUserAttributes((err, attributes) => {
            if (err) {
              reject(err)
              return
            }
            // console.log("Att: ", attributes);
            const userData = attributes.reduce((acc, attribute) => {
              acc[attribute.Name] = attribute.Value
              return acc
            }, {})
            
            // console.log("UserData: ", userData);
            // console.log("CogUser: ", cognitoUser);
            // console.log(`Token: ${cognitoUser.signInUserSession.idToken.jwtToken}`) //idToken
            resolve({ ...userData, username: cognitoUser.username, usertoken: cognitoUser.signInUserSession.idToken.jwtToken })
          })
        })
      })
  }
  
  export function getSession() {
    // Get session implementation, can use for token retrieval
    //EXAMPLE USE:
    // const session = await getSession()
    // const accessToken = session.accessToken
    // fetch("/api/protected", {
    // headers: {
    //  Authorization: `Bearer ${accessToken}`,
    // },
    // })

    const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    if (!cognitoUser) {
      reject(new Error("No user found"))
      return
    }
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
        return
      }
      resolve(session)
    })
  })
  }