import React, { useEffect, useState } from "react";

import { getCurrentUser } from "../../Auth/auth";

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Modal({visible, onClose, achievement}){

    //For disabling the submit button and dropzone
    const [isUploading, setUploading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    //Get the logged in user details so we know who is applying for the achievement
    const [user, setUser]= useState()
    useEffect(()=>{
        const fetchUser = async () => {
            try{
                const user = await getCurrentUser();
                setUser(user);
                // console.log(`User Details: ${JSON.stringify(user)}`);
                //console.log("modalUsr: ", user);
                // console.log("1: ", user['custom:role'])
            } catch(err){
                console.log(err)
            }
        }
        fetchUser();
    },[]);

    //Modal functionality to handle closing/showing the control
    const handleOnClose = (e)=>{
        if(e.target.id ==='ModalContainer'){
            onClose(achievement,false);
        }
    };

    if(!visible)
    {
        return null;
    }
    
    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) }

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = async(files) => {

    const f = files[0];
    
    // const fileName = "id_" + achievement.title;
    // console.log("User: ", user);
    // console.log("Achievement: ", achievement);
    // const fileName = "id_" + achievement.Title;
    const metadata = {
        userID : user.username,
        achievementID : achievement.AchievementID
    };
    const fileName = `${user.username}_${achievement.AchievementID}`;
    const group = "group_" + encodeURIComponent(achievement.AchievementGroup);
    const activity = "activity_" + encodeURIComponent(achievement.AchievementActivity);
    const title = "title_" + encodeURIComponent(achievement.Title);
    const desc = "desc_" + encodeURIComponent(achievement.Description);
    const url = "url_" + achievement.achievementURL;
    const userEmail = user.email;
    const fileType = f.file.type;
   
    const getPreSignedURL = 'https://40338odobj.execute-api.eu-west-2.amazonaws.com/testing/s3url?fileType=' + fileType + '&fileName=' + fileName;
    // const getPreSignedURL = 'https://40338odobj.execute-api.eu-west-2.amazonaws.com/testing/s3url?fileType=' + fileType + '&fileName=' + fileName + '&userEmail=' + userEmail;
    
    //console.log("Get PreSigned URL: ", getPreSignedURL);
    //console.log("QueryString: ", queryString);


    //Get the presigned S3 URL, includes file type, achievement ID as file name
    //const res = await fetch(getPreSignedURL,{
    // console.log(`METADATA: ${JSON.stringify(metadata)}`);
    // console.log(`METADATA: ${JSON.stringify({user.username, achievement.achievementID})}`);

    const res = await toast.promise(
        fetch(getPreSignedURL,{
            method: 'POST', // Using post so we can use a body
            headers: {
                'Content-Type':'application/json',
                'Token':user.usertoken
            },
            body: JSON.stringify({metadata})}),
            {
                // pending: 'Contacting server...',
                // success: 'Starting Upload',
                // error: 'Error!'
            }
    )

    const preSignedURL = await res.text();

    setUploading(true);

    //PUT request : Upload file to S3, wrapped in a toast promise for user feedback
    const result = await toast.promise(
        fetch(preSignedURL,{
        method: 'PUT',
        headers: {
            'Content-Type': fileType,
            'ptspocket-userID': user.username,
            'ptspocket-achievementID': achievement.AchievementID,
            'Token': user.usertoken
        },
        body: f['file']}),{
            pending: 'Uploading file...',
            success: 'File Uploaded!',
            error: 'Error!'
        });

        setUploading(false);
        console.log("File Uploaded!");

        setSubmitted(true);
        onClose(achievement,true);
    }
    
    return(
        <div id="ModalContainer" onClick={handleOnClose} className=" z-20 fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center">
        
            <div className="bg-white p-2 rounded w-1/2">      
                <Dropzone
                    onChangeStatus={handleChangeStatus}
                    onSubmit={handleSubmit}
                    canCancel={false}
                    accept="image/*, video/*"
                    // accept="image/jpg,image/jpeg, image/png, image/heic, image/heif, video/mp4, video/mov"
                    maxSizeBytes={104857600}
                    multiple={false}
                    maxFiles={1}
                    inputContent= "Add an image/video (100MB limit)"
                    disabled={isUploading}
                    submitButtonDisabled={isUploading}
                />
                {isUploading === true ? (
                  <div>
                  <p>File Uploading - Please Wait</p>  
                  </div>
                  ) : (
                  <p>{}</p>
                  )}
            </div>

      </div>
    )
}

export default Modal