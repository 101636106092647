import { useContext, useState } from "react"
import { AuthContext } from "./AuthContext"
import { Navigate, Link } from "react-router-dom"
import { useMediaQuery } from 'react-responsive'

export default function Login() {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")

  const {user, signIn } = useContext(AuthContext)
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 24px)' })
  const isTablet = useMediaQuery({ query: '(min-width: 425px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 424px)' })

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError("")

    try {
      await signIn(username, password)
      // Redirect to the app's main page or dashboard
    } catch (err) {
      setError(err.message)
    }
  }

  if(user){
    return <Navigate to="/" />
  }

  // if(isDesktopOrLaptop)
  // {
    return (
      <>
        {
          <div className="grid grid-cols-3 bg-PTSBackgroundMid w-full h-full overflow-hidden
            sm:grid-cols-6
            lg:grid-cols-12
          ">
            <div className="col-start-1 col-span-3  bg-PTSBackgroundDark mx-2 my-5 p-2 rounded-lg
              sm:col-start-2 sm:col-span-4
              lg:col-start-4 lg:col-span-6
              ">
              <input
                className="px-5 py-2 bg-PTSTextbox rounded-lg w-full"
                id="email"
                name="email"
                type="email"
                value={username}
                autoComplete="email"
                required
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Email"
              />
              <input
                className="px-5 py-2 mt-2 bg-PTSTextbox rounded-lg w-full"
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />

              <div className="grid grid-cols-3 my-2">
                <Link to="/signup">
                  <button  
                    className="col-start-1 col-span-1 w-full text-PTSBackgroundLight text-lg bg-PTSBackgroundMid border border-solid border-PTSBackgroundLight rounded-lg">
                      Register
                  </button>
                </Link>
                
                <button 
                  type="submit" 
                  onClick={handleSubmit} 
                  className="ml-2 col-start-2 col-span-2 text-PTSBackgroundDark text-lg bg-PTSAmber rounded-lg">
                  Login
                </button>
              </div>

              <Link to="/forgotpassword">
                <p className=" text-PTSBackgroundLight col-start-1 col-span-3">
                  <span className="text-PTSBackgroundLight underline">Forgotten</span>
                  <span className="text-PTSBackgroundLight">&nbsp;</span>
                  <span className="text-PTSBackgroundLight underline">Password </span>
                </p>
               </Link>

               {error && <p className="text-white">{error}</p>}

            </div>
          </div>
        }
      </>
  )
}