import { useState } from "react"
import { Link } from "react-router-dom"
import { confirmSignUp } from "./auth"

function ConfirmSignUp() {
  const [username, setUsername] = useState("")
  const [code, setCode] = useState("")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError("")

    try {
      await confirmSignUp(username, code)
      setSuccess(true)
    } catch (err) {
      setError(err.message)
    }
  }

  if (success) {
    return (

<>
           
<div className="grid grid-cols-3 bg-PTSBackgroundMid w-full h-full overflow-hidden
        sm:grid-cols-6
        lg:grid-cols-12">
        
        <div className="col-start-1 col-span-3 bg-PTSBackgroundDark text-PTSBackgroundLight mx-2 my-5 p-2 rounded-lg
          sm:col-start-2 sm:col-span-4
          lg:col-start-4 lg:col-span-6">  
          Confirmation successful!
          <Link to="/Login">
            <div className="mx-3 mt-9 mb-2 p-3 text-center bg-PTSAmber text-PTSBackgroundDark rounded-lg">
              Log In
            </div>                
          </Link>
          {error && <p className="text-white">{error}</p>}
        </div>  
      </div>

  </>

    )
  }

  return (

    <>

     
<div className="grid grid-cols-3 bg-PTSBackgroundMid w-full h-full overflow-hidden
            sm:grid-cols-6
            lg:grid-cols-12
          ">
            <div className="col-start-1 col-span-3  bg-PTSBackgroundDark mx-2 my-5 p-2 rounded-lg
              sm:col-start-2 sm:col-span-4
              lg:col-start-4 lg:col-span-6
              ">
              <input
                className="px-5 py-2 bg-PTSTextbox rounded-lg w-full"
                id="email"
                name="email"
                type="email"
                value={username}
                autoComplete="email"
                required
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Email"
              />
              <input
                className="px-5 py-2 mt-2 bg-PTSTextbox rounded-lg w-full"
                id="password"
                name="password"
                type="password"
                required
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="Code"
              />

              <div className="grid grid-cols-3 my-2">
                <button 
                  type="submit" 
                  onClick={handleSubmit}
                  className="mt-3 py-2 col-start-1 col-span-3 text-PTSBackgroundDark text-lg bg-PTSAmber rounded-lg">
                  Create Account
                </button>
              </div>
            </div>
</div>


     
    </>

 )

}

export default ConfirmSignUp