import React from 'react';

function NotFoundPage() {
  return (
    <div>
      Page not found
    </div>
  );
 }

 export default NotFoundPage