import { useState } from "react"
import { forgotPassword } from "./auth"
import { Link } from "react-router-dom"

function ForgotPassword() {
  const [username, setUsername] = useState("")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError("")

    try {
      await forgotPassword(username)
      setSuccess(true)
    } catch (err) {
      setError(err.message)
    }
  }

  if (success) {
    return (
        <>


<div className="grid grid-cols-3 bg-PTSBackgroundMid w-full h-full overflow-hidden
        sm:grid-cols-6
        lg:grid-cols-12">
        
        <div className="col-start-1 col-span-3  bg-PTSBackgroundDark text-PTSBackgroundLight mx-2 my-5 p-2 rounded-lg
          sm:col-start-2 sm:col-span-4
          lg:col-start-4 lg:col-span-6">  
            Check your email for the confirmation code to reset your password then click the link below.

          <Link to="/ResetPassword">
            <div className="mx-3 mt-9 mb-2 p-3 text-center bg-PTSAmber text-PTSBackgroundDark rounded-lg">
              Reset Password
            </div>                
          </Link>
        </div>
        {error && <p className="text-white">{error}</p>}
      </div>

        

      </>
    )
  }

return (
    <>

<div className="grid grid-cols-3 bg-PTSBackgroundMid w-full h-full overflow-hidden
            sm:grid-cols-6
            lg:grid-cols-12
          ">
            <div className="col-start-1 col-span-3  bg-PTSBackgroundDark mx-2 my-5 p-2 rounded-lg
              sm:col-start-2 sm:col-span-4
              lg:col-start-4 lg:col-span-6
              ">
              <input
                className="px-5 py-2 bg-PTSTextbox rounded-lg w-full"
                id="email"
                name="email"
                type="email"
                value={username}
                autoComplete="email"
                required
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Email"
              />

              <div className="grid grid-cols-3 my-2">
                <button 
                  type="submit" 
                  onClick={handleSubmit}
                  className="mt-3 py-2 col-start-1 col-span-3 text-PTSBackgroundDark text-lg bg-PTSAmber rounded-lg">
                  Send Reset Email
                </button>
              </div>
            </div>
</div>

       

  </>
)

}

export default ForgotPassword