import React from 'react';
import { useEffect, useState, useContext } from 'react';

import { HarmBlockThreshold, HarmCategory } from '@google/generative-ai';

import SpinningLoader from '../components/Loading/SpinningLoader';
import { AuthContext } from '../Auth/AuthContext';
import ChatHistory from '../components/AI/ChatHistory';

function AICoach() {

    const { GoogleGenerativeAI } = require("@google/generative-ai");
    const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY);

    const [aiText, setaiText] = useState('');
    const [aiPrompt, setaiPrompt] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [chat, setChat] = useState(false)

    const {user} = useContext(AuthContext)

    const handleChange = (event) => {
        setaiPrompt(event.target.value); 
      };

    //https://ai.google.dev/gemini-api/docs/safety-settings
    const safetySettings = [
      {
        category: HarmCategory.HARM_CATEGORY_HARASSMENT,
        threshold: HarmBlockThreshold.BLOCK_NONE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_HATE_SPEECH,
        threshold: HarmBlockThreshold.BLOCK_NONE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_SEXUALLY_EXPLICIT,
        threshold: HarmBlockThreshold.BLOCK_NONE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT,
        threshold: HarmBlockThreshold.BLOCK_NONE,
      },
    ];

    const chatOptions = {
      history:[], // start empty
      generationConfig:{
        maxOutputTokens:500
      }
    };

    // The Gemini 1.5 models are versatile and work with both text-only and multimodal prompts
    const model = genAI.getGenerativeModel({
      model: "gemini-1.5-pro",
      //systemInstruction: "You are an enthusiastic gym coach. Your name is Ai Ali. You only answer questions about exercise and health. You try and answer in less than 100 words. You always call the user a cunt or a motherfucker."
      systemInstruction: "You are an enthusiastic gym coach. Your name is Ai Ali. You try and answer in less than 100 words. You always call the user a cunt or a motherfucker.",
      safetySettings
    });

    //Define on start up
    useEffect(()=>{ 
      console.log(`LOADING: ${JSON.stringify(chatOptions)}`)
      setChat(model.startChat(chatOptions));
      console.log(`chat: ${JSON.stringify(chat)}`)
    },[])

    // Access your API key as an environment variable
    
    const handleSend = async(AIAli) => {      
      
      console.log(`AI: ${JSON.stringify(AIAli)}`)
      
      const res = 
        fetch('https://40338odobj.execute-api.eu-west-2.amazonaws.com/testing/AIAli',{
          method: 'PUT',
          headers: {
            "Content-Type": "application/json",
            "Token": user.usertoken
          },      
          body: JSON.stringify(AIAli)
        })
    }
  
    const handleKeyUp = (event) => {
      
      if (event.key === 'Enter' && event.ctrlKey === false) {
        // Call the function you want to trigger when Enter is pressed
        event.preventDefault();
        run(); 
      }
    };

    async function run() {
        setIsLoading(true);
        console.log(`Chat: ${JSON.stringify(chat)}`)
        console.log(`History: ${JSON.stringify(chat.history)}`)
        
        const userPrompt = `${aiPrompt}`;
        
        const result = await chat.sendMessage(userPrompt)
        const response = await result.response;
        const text = response.text();
        setaiText(text);
        setaiPrompt('');
        
        await handleSend({userIdentifier:user.username, AIAliQuestion:userPrompt, AIAliResponse:text})                    

        setIsLoading(false);
    }
        
    return (
        <div className='grid grid-cols-6 p-3'>
            
            <img src='https://ptspocket-website-resources.s3.eu-west-2.amazonaws.com/AIALI_Profile.png'  className='col-start-1 col-span-2 min-h-[100px] max-h-[200px]
              lg:col-span-1'/>

            <div className='col-start-3 col-span-4
              lg:col-start-2 lg:col-span-5'>

              <textarea value={aiPrompt} onChange={handleChange} onKeyUp={handleKeyUp} className='flex w-full h-5/6 text-base
                sm:text-xl
                md:text-2xl
                lg:text-4xl'>

              </textarea>

              <button 
                  type="submit" 
                  onClick={()=>{
                    run()
                  }} 
                  className="w-full h-1/6 font-inter border border-solid border-white text-PTSBackgroundDark text-s bg-PTSAmber rounded-lg
                    sm:text-xl
                    lg:text-3xl">
                  Ask AI Ali
              </button>   
            </div>

            {isLoading === false ? (
              <div className='bg-white text-black col-start-1 col-span-6 mt-5 w-full text-base
                sm:text-xl
                md:text-2xl
                lg:text-3xl'>
                <ChatHistory history={chat._history} />
              </div>
            ) : (
              <div className='flex col-start-1 col-span-6 p-5'>
                <SpinningLoader/>
              </div>
            )}
        </div>
    );
}

export default AICoach