function ChatHistory({history}) {

  if (!history || history.length === 0) {
    return null; // Return nothing if data is null or empty
  }

  // console.log(`HISTORY: ${JSON.stringify(history)}`)

        // Step 1: Reverse the data
        const reversedHistory = [...history].reverse(); // Shallow copy then reverse

        // console.log(`HISTORY REVERSED: ${JSON.stringify(reversedHistory)}`)
        // Step 2: Reorder user/model pairs
        const reorderedHistory = [];

        for (let i = 1; i < reversedHistory.length; i += 2) {
          reorderedHistory.push(reversedHistory[i]);
          reorderedHistory.push(reversedHistory[i-1]);
        }  

        // console.log(`HISTORY REORDERED: ${JSON.stringify(reorderedHistory)}`)

    
    return (
        <div className="flex flex-col space-y-2 bg-PTSBackgroundMid">
          {reorderedHistory.map((item, index) => (
            <div
              key={index}
            //   className={`text-${index % 2 === 0 ? 'left' : 'right'}`}
            className={`text-${index % 2 === 0 ? 'black' : 'white'} 
                      bg-${index % 2 === 0 ? 'white' : 'black'} 
                      p-2 rounded-md
                      ${index % 2 === 0 ? 'text-left' : 'text-right'}`}
            >
              {item.parts[0].text}
            </div>
          ))}
        </div>
    );
}

export default ChatHistory;