import { Fragment, useContext, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from "react-router-dom"

import { DropdownMenu} from "./UserDropDownMenu"

import { AuthContext } from "../../Auth/AuthContext"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

//https://tailwindui.com/components/application-ui/navigation/navbars
//Simple dark with menu button on left
function UserHeader(){
  
    const {user, signOut} = useContext(AuthContext)
    
    // If logged in use simple header 
    if(!user){
      return (
        <div className='grid grid-cols-3 bg-PTSBackgroundDark
          sm:grid-cols-6
          lg:grid-cols-12 
        '>
      
          {/* PTS POCKET Text */}
          <Link to="/" className='font-inter flex items-center col-start-1 col-span-3
            sm:col-start-2 sm:col-span-4
            lg:col-start-4 lg:col-span-8
          '>
            <div className='text-white pt-1 pl-5 pb-5 text-5xl'>
              pts
            </div>  
            <div className='text-PTSAmber pt-1 pb-5 text-5xl'>
              pocket
            </div>
          </Link>
        </div>
      )
    }
    
    // Otherwise return full header
    return(
    <div className='grid grid-cols-3 bg-PTSBackgroundDark
      sm:grid-cols-6  
      lg:grid-cols-12 
    '>

      {/* PTS POCKET Text */}
      <Link to="/" className='font-inter flex items-center col-start-1 col-span-2
        sm:col-start-1 sm:col-span-2
        lg:col-start-1 lg:col-span-5 
      '>
        <div className='text-white py-1 pl-5 text-3xl
          sm:text-5xl
          '>
          pts
        </div>  
        <div className='text-PTSAmber py-1 text-3xl
          sm:text-5xl
          '>
          pocket
        </div>
      </Link>

      {/* User Log In Profile */}
      <div className='flex items-center justify-self-end p-1  col-start-3 col-span-1
        sm:col-start-5 sm:col-span-2
        lg:col-start-11 lg:col-span-2 
      '>
          <DropdownMenu signOut={signOut} />
      </div>
    </div>
    )
};

export default UserHeader
