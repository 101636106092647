import React, { useEffect, useState, useContext } from 'react';
import AchievementGridV2 from '../components/Grids/AchievementGridV2';
import AchievementGrid from '../components/Grids/AchievementGrid';
import { AuthContext } from '../Auth/AuthContext';

const testData = [
  {GroupName:'Group1',Activities:[{ActivityName:'Activity1',Achievements:[{AchievementName:'Achievement1'},{AchievementName:'Achievement2'},{AchievementName:'Achievement3'},{AchievementName:'Achievement4'}]},{ActivityName:'Activity2',Achievements:[{AchievementName:'Achievement5'}]}]}
]

function TestPage() {

  const {user} = useContext(AuthContext)

  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  //Define the function for getting the users achievements 
  const getAchievements = async ()=>{
    setIsLoading(true);
    const res = await fetch(`https://40338odobj.execute-api.eu-west-2.amazonaws.com/testing/achievements?id=${user.username}`,{
      method: 'GET',
      headers:{
        'Token': `${user.usertoken}`
      }});
    
    const data = await res.json();
    setItems(formatData(data));
    setIsLoading(false);
  };
  
  //Get achievements from the API
  useEffect(()=>{
    getAchievements();
  },[]);

  function formatData(data){

    // console.log("Achievement Data In: ", data);
    const result = data[0].map(group => {
      return {
          ...group,
          Activities: data[1]
              // .filter(activity => activity.GroupID === group.GroupID && (activity.ActivityID === selectedActivity || selectedActivity === -1))
              .filter(activity => activity.GroupID === group.GroupID)
              .map(activity => {
                  return {
                      ...activity,
                      Achievements: data[2].filter(achievement => achievement.ActivityID === activity.ActivityID)
                      .map(achievement => {
                          return{
                            ...achievement,
                            UserAchievement: data[3].filter(userAchievement => userAchievement.AchievementID == achievement.AchievementID)
                          }                     
                      })
                  };
              })
      };
    });
  
    // console.log("Achievement Data Out: " + JSON.stringify(result, null, 2));
  
    return result;
  }

  return (
    <>
    <AchievementGridV2 data={items}>

    </AchievementGridV2>
    </>
  )
 }

 export default TestPage