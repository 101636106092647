import { Fragment, useContext } from 'react'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

import { AuthContext } from "../../Auth/AuthContext"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


// https://tailwindui.com/components/application-ui/elements/dropdowns
// https://headlessui.com/react/menu

export function DropdownMenu({signOut}) {
  
  const {user} = useContext(AuthContext)
  
  return (
    <Menu as="div" className="relative inline-block text-left">

      

      <div>
        <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md  px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm">
          <img
            className="flex h-10 w-10 rounded-full border border-PTSBackgroundLight hover:cursor-pointer hover:bg-PTSBackgroundLight
            sm:h-16 sm:w-16
            lg:h-16 lg:w-16
            "
            src="https://ptspocket-website-resources.s3.eu-west-2.amazonaws.com/Union.png"
            alt=""
          />
        </MenuButton>
      </div>

      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none
          sm:w-44
          lg:w-56
        ">
          <div className="py-1">

            <MenuItem>
                {({ focus }) => (
                  // <p className={classNames(' text-PTSBackgroundDark block w-full px-4 py-2 text-left text-sm')}
                  // >
                  //   {user['name']}
                  // </p>
                  <a href="/" 
                  className={classNames(
                    focus ? 'bg-PTSBackgroundLight text-PTSBackgroundDark hover:cursor-pointer' : 'text-gray-700','block w-full px-4 py-2 text-left text-sm'
                  )}
                >
                  {user['name']}
                </a>
                )}
            </MenuItem>

            {/* Seperator */}
            <div className="my-1 h-px bg-PTSBackgroundLight" /> 

            <MenuItem>
                {({ focus }) => (
                  <a href="/achievements" 
                  className={classNames(
                    focus ? 'bg-PTSBackgroundLight text-PTSBackgroundDark hover:cursor-pointer' : 'text-gray-700','block w-full px-4 py-2 text-left text-sm'
                  )}
                >
                  Achievements
                </a>
                )}
              </MenuItem>

            
                  
            <MenuItem>
              {({ focus }) => (
                <a href="/aicoach" 
                  className={classNames(
                    focus ? 'bg-PTSBackgroundLight text-PTSBackgroundDark hover:cursor-pointer' : 'text-gray-700','block w-full px-4 py-2 text-left text-sm'
                  )}
                >
                  AI Ali
                </a>
              )}
            </MenuItem>

            <div className="my-1 h-px bg-PTSBackgroundLight" />

            {user['custom:role'] === "admin" ? (
                  
                  <MenuItem>
                  {({ focus }) => (
                    <a href="/achievementapproval" 
                      className={classNames(
                        focus ? 'bg-PTSBackgroundLight text-PTSBackgroundDark hover:cursor-pointer' : 'text-gray-700','block w-full px-4 py-2 text-left text-sm'
                      )}
                    >
                      Outstanding Approvals
                    </a>
                  )}
                </MenuItem>
                  ) : (
                  <p>{}</p>
                  )}


            <MenuItem>
                {({ focus }) => (
                  <p className={classNames(
                      focus ? 'bg-PTSBackgroundLight text-PTSBackgroundDark hover:cursor-pointer' : 'text-gray-700',
                      'block w-full px-4 py-2 text-left text-sm'
                    )}
                    onClick={signOut}
                  >
                    Sign out
                  </p>
                )}
              </MenuItem>
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  )
}
