export const cognitoConfig = {
    //PoolV1
    //UserPoolId: "eu-west-2_46pvLBF8k",
    //ClientId: "79ua4vk21dv679cgoqtt2cat91",
    
    //PoolV2 - Adjusted password etc
    // UserPoolId: "eu-west-2_TVSkBk7ex",
    // ClientId: "pjuu3971s5q7ee39piepun76e"

    //PoolV3 - Adjusted password etc
    UserPoolId: "eu-west-2_ucBIdk3pY",
    ClientId: "5hl45a3sg9u3l4k0428tunorsg"
  }