import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import React, { useState, useEffect, useRef } from 'react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Calendar() {

    const [workoutLog, setWorkoutLog] = useState([
        { date: '2024-08-26', isCurrentMonth: false, isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
        { date: '2024-08-27', isCurrentMonth: false, isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
        { date: '2024-08-28', isCurrentMonth: false, isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
        { date: '2024-08-29', isCurrentMonth: false, isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
        { date: '2024-08-30', isCurrentMonth: false, isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
        { date: '2024-08-31', isCurrentMonth: false, isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
        { date: '2024-09-01', isCurrentMonth: true, isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-02', isCurrentMonth: true, isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: false
              },
              {
                  name: 'Cardio',
                  complete: false
              },
              {
                  name: 'Mobility',
                  complete: false
              },
              {
                  name: 'Skill/Sport',
                  complete: false
              }
          ]
        },
        { date: '2024-09-03', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: false
              },
              {
                  name: 'Skill/Sport',
                  complete: false
              }
          ]
        },
        { date: '2024-09-04', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: false
              }
          ]
        },
        { date: '2024-09-05', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-06', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-07', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-08', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-09', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-10', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-11', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-12', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-13', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-14', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-15', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-16', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-17', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-18', isCurrentMonth: true,  isToday: false, isSelected: true,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-19', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-20', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-21', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-22', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-23', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-24', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-25', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-26', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-27', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-28', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-29', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-30', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-10-01', isCurrentMonth: false,  isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
        { date: '2024-10-02', isCurrentMonth: false,  isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
        { date: '2024-10-03', isCurrentMonth: false,  isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
        { date: '2024-10-04', isCurrentMonth: false,  isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
        { date: '2024-10-05', isCurrentMonth: false,  isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
        { date: '2024-10-06', isCurrentMonth: false,  isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
      ])

      const checkboxRefs = useRef({});

    const handleDateClick = (clickedDate) => {
        //Creates a new array of data, if the date is equal to the clickedDate isSelected set to true, otherwise set to false
        setWorkoutLog(prevWorkoutLog => prevWorkoutLog.map(item => 
            ({ ...item, isSelected: item.date === clickedDate.date })  
        ));
    };

    const handleLogClick = (selectedDate, itemClicked, checked) => {
        
        setWorkoutLog(prevWorkoutLog => prevWorkoutLog.map(item => 
            // Check if the date matches the date you want to update
            item.date === selectedDate.date
                ?                      
                    { 
                        ...item, // Spread the existing item to keep other properties unchanged
                        log: item.log.map(entry =>
                            // Check if the log entry is the same as the one clicked
                            entry.name === itemClicked.name  
                                ? { ...entry, complete: !entry.complete} // Toggle the complete status
                                : entry // Otherwise, keep the entry as is
                        )
                    }
                : item // If the date doesn't match, keep the item as is
            ));
    }

    //Get which date is selected
    const findSelectedDate = () =>
    {
        const selectedItem = workoutLog.find(item => item.isSelected);
        return selectedItem ? selectedItem : null;
    };

    //Get which month is selected
    const findSelectedMonth = () =>
    {
        const selectedItem = workoutLog.find(item => item.isCurrentMonth);
        return new Date().toLocaleDateString('en-US', { month: 'long' })
    };

    //How many logs complete for a date
    const countLogsDone = (data) =>
    {
        return data.log.filter(entry => entry.complete).length;
    }

    const selectedDate = findSelectedDate();
    const selectedLog = selectedDate.log;
    const selectedMonth = findSelectedMonth();
    
  return (
    <div>
      <div className="flex items-center">
        <h2 className="flex-auto text-3xl font-semibold text-gray-900">{selectedMonth} 2024</h2>
        <button
          type="button"
          className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-10 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
        <div>S</div>
      </div>
      <div className="mt-2 grid grid-cols-7 text-sm">
        {workoutLog.map((date, dayIdx) => (
          <div key={date.date} className={classNames(dayIdx > 6 && 'border-t border-gray-200', 'py-2')}>
            <button
              type="button"
              className={classNames(
                date.isCurrentMonth && !date.isSelected && countLogsDone(date) === 0 && 'bg-red-400',
                date.isCurrentMonth && !date.isSelected && countLogsDone(date) >= 1 && countLogsDone(date) < 4 && 'bg-yellow-300',
                date.isCurrentMonth && !date.isSelected && countLogsDone(date) === 4 && 'bg-green-500',
                !date.isCurrentMonth && 'text-gray-500',
                date.isSelected && 'bg-gray-400',
                date.isSelected && 'font-semibold',
                date.isSelected && 'text-white',
                !date.isSelected && 'text-black',
                !date.isSelected && 'hover:bg-gray-200',
                'mx-auto flex h-8 w-8 items-center justify-center rounded-full',
              ) }
              onClick={()=> handleDateClick(date)}
            >
              <time dateTime={date.date}>{date.date.split('-').pop().replace(/^0/, '')}</time>
            </button>
          </div>
        ))}
      </div>
      <section className="mt-12">
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          Training Log for <time dateTime="2022-01-21">September 18, 2024</time>
        </h2>
            {selectedLog && (
                <ul>
                    {selectedLog.map((item,index) => (
                        <li key={index}>
                            <div className="h-6">
                                <input 
                                    // id="comments" 
                                    // aria-describedby="comments-description" 
                                    // name="comments" 
                                    key={item.name}
                                    className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-indigo-600"
                                    type="checkbox" 
                                    ref={el => checkboxRefs.current[item.name] = el}
                                    onClick={(event)=> handleLogClick(selectedDate,item,event.target.checked)}
                                    checked={item.complete}
                                    >
                                    
                                </input>
                                <label className="p-2 font-medium text-black">{item.name}</label>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
            
                        
      </section>
    </div>
  )
}
