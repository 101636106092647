import React from 'react';
import { useEffect, useState, useContext } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from '../Auth/AuthContext';

import ApprovalGrid from '../components/Grids/ApprovalGrid'
import SpinningLoader from '../components/Loading/SpinningLoader';

function AchievementApproval() {

  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const {user} = useContext(AuthContext)

  //Get achievements from the API
  useEffect(()=>{
    console.log("Getting Data")
    getAchievements();
  },[]);
  
  const getAchievements = async ()=>{
    setIsLoading(true);
    const res = await fetch(`https://40338odobj.execute-api.eu-west-2.amazonaws.com/testing/achievements/approval`,{
      method: 'GET',
      headers: {
        "Token": user.usertoken
      }});
   
    const data = await res.json();
    
    setItems(formatData(data));
    setIsLoading(false);
  };

  function formatData(data){

    const result = data.map(PendingApproval => {
      return {
        UserName:PendingApproval.UserName,
        UserID:PendingApproval.UserID,
        AchievementID:PendingApproval.AchievementID,
        DateAchieved:PendingApproval.DateAchieved,
        ApprovalStatusID:PendingApproval.ApprovalStatusID,
        ApprovalComments:PendingApproval.ApprovalComments,
        GroupName:PendingApproval.GroupName,
        ActivityName:PendingApproval.ActivityName,
        AchievementName:PendingApproval.AchievementName,
        AchievementDescription:PendingApproval.Description,
        AchievementEvidenceURL:PendingApproval.EvidenceURL
      };
    });
  
    return result;
  }

  function removeItemByProperties(prop1, value1, prop2, value2) {
    return items.filter(obj => {
        return !(obj.hasOwnProperty(prop1) && obj[prop1] === value1 
                 && obj.hasOwnProperty(prop2) && obj[prop2] === value2);
    });
  }

const handleApprove = async(achievement) => {
 
  console.log(`Handle Approve: ${JSON.stringify(achievement)}`);
  
  const res = await toast.promise(
    fetch('https://40338odobj.execute-api.eu-west-2.amazonaws.com/testing/achievements/approval',{
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        "Token": user.usertoken
      },      
      body: JSON.stringify(achievement)
    }),{
      pending: 'Approving...',
      success: 'Approved!',
      error: 'Error!'
    }
  );
  
  //Update the page content
  setItems(formatData(removeItemByProperties('UserID',achievement.UserID,'AchievementID',achievement.AchievementID)));
  //getAchievements();
}

const handleReject = async(achievement) => {
 
  // console.log(`Handle Reject: ${JSON.stringify(achievement)}`);

  // const today = new Date();
  const res = await toast.promise(
    fetch('https://40338odobj.execute-api.eu-west-2.amazonaws.com/testing/achievements/approval',{
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        "Token": user.usertoken
      },      
      body: JSON.stringify(achievement)
    }),{
      pending: 'Rejecting...',
      success: 'Rejected!',
      error: 'Error!'
    }
  );
  
  //Update the page content
  setItems(formatData(removeItemByProperties('UserID',achievement.UserID,'AchievementID',achievement.AchievementID)));
  //getAchievements();
}

    return (
      // <div className='overflow-auto h-full'>
      <div className='grid grid-cols-3 bg-PTSBackgroundMid
        sm:grid-cols-6
        lg:grid-cols-12'> 
    
        <div className=' col-start-1 col-span-3
          sm:col-span-6
          lg:col-span-12'>
          
          {isLoading === false ? (
            <ApprovalGrid items={items} handleApprove={handleApprove} handleReject={handleReject}/>
          ) : (
            <div className='flex p-5'>
              <SpinningLoader/>
            </div>
          )}
      </div>

    <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />

        </div>
    );
}

export default AchievementApproval