import { useState } from "react"
import { Link } from "react-router-dom"
import { signUp } from "./auth"

function SignUp() {
  // const [username, setUsername] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError("")

    try {
      await signUp(email, name, email, password)
      setSuccess(true)
    } catch (err) {
      setError(err.message)
    }
  }

  //If in successul state submitting account details for registration
  if (success) {
    return(
    <>        
      {/* <div className="grid grid-cols-3 bg-PTSBackgroundMid w-full h-full overflow-hidden
        sm:grid-cols-6
        lg:grid-cols-12">
        
        <div className="col-start-1 col-span-3  bg-PTSBackgroundDark text-PTSBackgroundLight mx-2 my-5 p-2 rounded-lg
          sm:col-start-2 sm:col-span-4
          lg:col-start-4 lg:col-span-6">  
          Sign up successful, please check your email for the confirmation code then click below

          <Link to="/ConfirmSignUp">
            <div className="mx-3 mt-9 mb-2 p-3 text-center bg-PTSAmber text-PTSBackgroundDark rounded-lg">
              Confirm Account
            </div>                
          </Link>
          {error && <p className="text-white">{error}</p>}
        </div>  
      </div> */}

      <div className="grid grid-cols-3 bg-PTSBackgroundMid w-full h-full overflow-hidden
        sm:grid-cols-6
        lg:grid-cols-12">
        
        <div className="col-start-1 col-span-3  bg-PTSBackgroundDark text-PTSBackgroundLight mx-2 my-5 p-2 rounded-lg
          sm:col-start-2 sm:col-span-4
          lg:col-start-4 lg:col-span-6">  
          <p className="text-center">Registration successful</p>
          <br></br>
          <p className="text-center">An administrator will authorise your account shortly</p>

          <Link to="/">
            <div className="mx-3 mt-9 mb-2 p-3 text-center bg-PTSAmber text-PTSBackgroundDark rounded-lg">
              Login
            </div>                
          </Link>
        </div>  
      </div>
  </>
  )
  }

  //If not in successful state submitting account details for registering
  return(
    <>

<div className="grid grid-cols-3 bg-PTSBackgroundMid w-full h-full overflow-hidden
            sm:grid-cols-6
            lg:grid-cols-12
          ">
            <div className="col-start-1 col-span-3  bg-PTSBackgroundDark mx-2 my-5 p-2 rounded-lg
              sm:col-start-2 sm:col-span-4
              lg:col-start-4 lg:col-span-6
              ">
              <input
                className="px-5 py-2 bg-PTSTextbox rounded-lg w-full"
                id="name"
                name="name"
                type="name"
                value={name}
                autoComplete="name"
                required
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
              <input
                className="px-5 py-2 mt-2 bg-PTSTextbox rounded-lg w-full"
                id="email"
                name="email"
                type="email"
                value={email}
                autoComplete="email"
                required
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
              <input
                className="px-5 py-2 mt-2 bg-PTSTextbox rounded-lg w-full"
                id="password"
                name="password"
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />

              <div className="m-2">         
                <p className="text-white flex">Password requirements:</p>
                <ul className="list-disc pl-5">
                  <li className="text-white">8 Characters</li>
                  <li className="text-white">1 Number</li>
                </ul>
              </div>

              <div className="grid grid-cols-3 my-2">
                <button 
                  type="submit" 
                  onClick={handleSubmit}
                  className="m-2 py-2 col-start-1 col-span-3 text-PTSBackgroundDark text-lg bg-PTSAmber rounded-lg">
                  Create Account
                </button>
              </div>

              {error && <p className="text-white">{error}</p>}
            </div>
</div>
    </>
  )
}

export default SignUp