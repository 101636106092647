// 
// 320
// 480
// 720
// 

import React, { useState, useRef, useEffect } from 'react';
import { formatDate } from '../../functionality/dateFunctions';
import { BackwardIcon } from '@heroicons/react/20/solid';
import { Switch } from '@headlessui/react'

function AchievementGridV2({ data, onUserApproveClick, onUserRejectClick, onSubmit }) {
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedAchievement, setSelectedAchievement] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [hideComplete, setHideComplete] = useState(false);
  const [enabled, setEnabled] = useState(false)

  const handleBack = () => {
    if(selectedAchievement){
        setSelectedAchievement(null);
    } else if (selectedActivity) {
        setSelectedActivity(null);
    } else if (selectedGroup) {
        setSelectedGroup(null);
    }
  };

  const handleSearchStringchange = event => {
    setSearchString(event.target.value);
  }

  const getAchievementStatusComponent = (Achievement) => {
    switch (Achievement.UserAchievement[0].ApprovalStatusID) {
      case 0:
        return <p className="absolute bottom-2 mb-3 font-normal text-AchievementTextLight z-[1]">Achieved (Pending) {formatDate(Achievement.UserAchievement[0].DateAchieved)}</p>;
      case 1:
        return(
        <div className='flex flex-col'>
          <p className="absolute bottom-6 mb-3 font-normal text-AchievementTextLight z-[1]">Rejected - {Achievement.UserAchievement[0].ApprovalComments}</p>;
          <button onClick={()=>{
            onUserRejectClick(Achievement.UserAchievement[0])
          }} 
            className="z-10 absolute bottom-2 left-0 w-full font-inter border border-solid border-white text-PTSBackgroundDark text-lg bg-PTSAmber rounded-lg">OK</button>
        </div>)
      case 2:
        return(
          <div className='flex flex-col'>
            <p className="absolute bottom-6 mb-3 font-normal text-AchievementTextLight z-[1]">Approved - {Achievement.UserAchievement[0].ApprovalComments}</p>;
            <button onClick={()=>{
              Achievement.UserAchievement[0].ApprovalStatusID = 3
              onUserApproveClick(Achievement.UserAchievement[0])
            }} 
              className="z-10 absolute bottom-2 left-0 w-full font-inter border border-solid border-white text-PTSBackgroundDark text-lg bg-PTSAmber rounded-lg">OK</button>
          </div>)
      case 3:
        return <p className="absolute bottom-2 mb-3 font-normal text-AchievementTextLight z-[1]">Achieved {formatDate(Achievement.UserAchievement[0].DateAchieved)}</p>;
      default:
        return <p>Status: Unknown</p>;
    }
  };

  const getAchievementStatusText = (Achievement) => {
    switch (Achievement.UserAchievement[0].ApprovalStatusID) {
      case 0:
        return 'Achieved (Pending)';
      case 1:
        return 'Rejected';
      case 2:
        return 'Approved'; 
      case 3:
        return 'Achieved';
      default:
        return 'Status: Unknown'
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  useEffect(()=>{ 
    setFilteredItems(filterActivities());
  },[data,hideComplete, searchString])


  function filterActivities() {
       const filteredData = data.map(group => {
        return {
          ...group,
          Activities: group.Activities.map(activity =>({
            ...activity,
            Achievements: activity.Achievements.filter(achievement => (achievement.UserAchievement.length === 0 || hideComplete === false) && (achievement.Name.toLowerCase().includes(searchString.toLowerCase())))
          })).filter(activity => activity.Achievements.length > 0)
        };
    }).filter(group => group.Activities.length > 0);

    //find new selected item else move back a level
    if(selectedGroup != null){
      console.log(`In Group`)
      if(doesGroupExist(filteredData,selectedGroup.GroupID) === false){
        setSelectedGroup(null)
      }
    }

    if(selectedActivity != null){
      console.log(`In Activity`)
      if(doesActivityExist(filteredData,selectedActivity.ActivityID) === false){
        setSelectedActivity(null)
      }
    }

    if(selectedAchievement != null){
      console.log(`In Achievement`)
      if(doesAchievementExist(filteredData,selectedAchievement.AchievementID) === false){
        setSelectedAchievement(null)
      }
    }
    return filteredData;
  }

  function doesAchievementExist(data, targetAchievementID) {
    var result = false;

    for (const group of data) {
      for (const activity of group.Activities) {
        for (const achievement of activity.Achievements) {
          if (achievement.AchievementID === targetAchievementID) {
            result = true; // Found the achievement!
            setSelectedAchievement(achievement);
          }
        }
      }
    }
    console.log(`AchievementResult: ${result}`)
    return result;
  }

  function doesActivityExist(data, targetActivityID) {
    var result = false;

    for (const group of data) {
      for (const activity of group.Activities) {
        if (activity.ActivityID === targetActivityID) {
          result = true; // Found the activity!
          setSelectedActivity(activity);
        } 
      }
    }
    console.log(`ActivityResult: ${result}`)
    return result
  }

  function doesGroupExist(data, targetGroupID) {
    var result = false;
    
    for (const group of data) {
      if (group.GroupID === targetGroupID) {
        result = true; // Found the group!
        setSelectedGroup(group);
      }
    }
    console.log(`GroupResult: ${result}`)
    return result;
  }

  return (
    
    <div className="grid grid-cols-3 gap-1 p-4
        xs:grid-cols-4
        sm:grid-cols-5
        md:grid-cols-6
        lg:grid-cols-8">

    <div className='col-span-full grid grid-cols-8 bg-PTSBackgroundDark'>
      {console.log(`Data In: ${data}`)}
      {/* Search Box */}
      <div className="p-2 col-span-5 flex rounded-md shadow-sm">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <input
            type="email"
            name="email"
            id="email"
            onChange= {handleSearchStringchange}
            className="block w-full rounded-lg border-0 py-1.5 text-black ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-AchievementBackgroundMid text-xs
              sm:text-sm sm:leading-6"
            placeholder="Search"
          />
        </div>
      </div>

      {/* Hide Complete */}
      <div className='col-span-3 items-center p-2 '>
        <div className="flex items-center justify-between w-full p-2">
   
          <span className="text-xs text-AchievementTextLight
            sm:text-base">
            Hide Complete        
          </span>

          <Switch
            checked={enabled}
            onChange={setEnabled}
            onClick={()=> setHideComplete(!hideComplete)}
            className={classNames(
              enabled ? 'bg-PTSAmber' : 'bg-gray-200', 'relative inline-flex h-6 w-8 lg:w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-AchievementBackgroundMid focus:ring-offset-2'
            )}>
              <span aria-hidden="true" className={classNames(
                enabled ? 'translate-x-2 lg:translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
              )}/>
          </Switch>
        </div>
      </div>
    </div>

    {/* {console.log(`GRID DATA: ${JSON.stringify(filteredItems)}`)} */}
    { selectedAchievement ? (
    <div className='col-span-full grid grid-cols-8'>
        <div className='col-span-full border border-PTSAmber p-2 rounded text-lg text-center font-inter text-PTSBackgroundLight flex justify-center items-center bg-PTSBackgroundDark'>
            ACHIEVEMENT
        </div>
        <div
            className='col-start-1 col-span-1 border border-PTSAmber p-2 rounded cursor-pointer text-xs text-center text-PTSBackgroundLight flex justify-center items-center aspect-square bg-PTSBackgroundDark'
            onClick={handleBack}
        >
            <BackwardIcon className='w-6 text-PTSAmber'/>
        </div>
        <div className='col-start-2 col-span-7'>
            <div key={selectedAchievement.AchievementID} className='bg-transparant rounded-lg'>
              <ul>
                <li className="border border-PTSAmber p-2 bg-AchievementBackgroundDark bg-no-repeat bg-contain bg-right relative h-48 rounded-lg shadow-2xl shadow-black">
                  <div className="object-cover flex flex-col justify-between px-3 py-1 leading-normal">
                    <h5 className="mb-2 text-2xl font-inter tracking-tight text-AchievementTextLight z-[1]">{selectedAchievement.Name}</h5>
                    <p className="mb-3 font-inter text-AchievementTextMid z-[1]">{selectedAchievement.Description}</p>
                    {
                      selectedAchievement.UserAchievement.length === 0 ? (
                        <button 
                          type="submit" 
                          onClick={()=> onSubmit({"AchievementGroup": selectedGroup.Name, "AchievementActivity": selectedActivity.Name, "AchievementID": selectedAchievement.AchievementID, "Title":selectedAchievement.Name,"Description":selectedAchievement.Description, "achievementURL":selectedActivity.ActivityImgURL})} 
                          className="z-10 absolute bottom-0 left-0 w-full font-inter border border-solid border-white text-PTSBackgroundDark text-lg bg-PTSAmber rounded-lg">
                            Apply
                        </button>                            
                      ) : (                             
                        getAchievementStatusComponent(selectedAchievement)
                      )
                    }
                    
                    <img className=' h-full opacity-50 absolute bottom-0 right-0 overflow-hidden z-0' src={selectedActivity.ActivityImgURL}></img>
                  </div>
                </li>
              </ul> 
            </div>
        </div>
    </div>

    ) : selectedActivity ? (
      <>
        {/* {console.log(JSON.stringify(selectedActivity))} */}
        <div className='col-span-full border border-PTSAmber p-2 rounded text-lg text-center font-inter text-PTSBackgroundLight flex justify-center items-center bg-PTSBackgroundDark'>
            ACHIEVEMENTS
        </div>
        <div
          className="border border-PTSAmber p-2 rounded cursor-pointer text-xs text-center font-inter text-PTSBackgroundLight flex justify-center items-center aspect-square bg-PTSBackgroundDark"
          onClick={handleBack}
        >
          <BackwardIcon className='w-6 text-PTSAmber'/>
        </div>
        {selectedActivity.Achievements.map((achievement, index) => (
            <div key={index} 
                // className='grid grid-cols-2 border border-PTSAmber p-2 rounded cursor-pointer text-xs text-center font-inter text-PTSBackgroundLight aspect-square bg-PTSBackgroundDark bg-[url("https://cdn.pixabay.com/photo/2012/04/05/01/15/stickman-25582_1280.png")] bg-cover bg-center'
                className={`grid grid-cols-2 border border-PTSAmber p-2 rounded cursor-pointer text-xs text-center font-inter text-PTSBackgroundLight aspect-square bg-PTSBackgroundDark bg-no-repeat`}
                style={{ 
                  backgroundImage: `linear-gradient(rgba(9, 9, 11, 0.7), rgba(9, 9, 11, 0.7)), url(${selectedActivity.ActivityImgURL || ''})`, 
                  backgroundSize: '50%',  // Increased size to shrink after opacity
                  backgroundPosition: 'center',
                  // opacity: 0.5, // 50% transparency
                }}
                onClick={() => setSelectedAchievement(achievement)}>
                <div className="col-start-1 col-span-full flex justify-center items-center">
                    <p className=''>
                        {achievement.Name}
                    </p>
                </div>
                {achievement.UserAchievement.length == 0 ? (
                    <div className='col-start-1 col-span-full flex justify-end items-end'>
                        Not Achieved
                    </div>
                ) : (
                    <div className='col-start-1 col-span-full flex justify-end items-end'>
                        {/* Achieved */}
                        {console.log(`Achi Square: ${JSON.stringify(achievement)}`)}
                        <div className='col-start-1 col-span-full flex justify-end items-end'>
                          {getAchievementStatusText(achievement)}
                        </div>
                    </div>
                )}
            </div>
        ))}
      </>
    ) : selectedGroup ? (
      <>
        <div className='col-span-full border border-PTSAmber p-2 rounded text-lg text-center font-inter text-PTSBackgroundLight flex justify-center items-center bg-PTSBackgroundDark'>
            ACTIVITIES
        </div>
        <div
          className="border border-PTSAmber p-2 rounded cursor-pointer text-xs text-center font-inter text-PTSBackgroundLight flex justify-center items-center aspect-square bg-PTSBackgroundDark"
          onClick={handleBack}
        >
          <BackwardIcon className='w-6 text-PTSAmber'/>
        </div>
        {selectedGroup.Activities.map((activity, index) => (
            <div key={index} className='grid grid-cols-2 border border-PTSAmber p-2 rounded cursor-pointer text-xs text-center font-inter text-PTSBackgroundLight aspect-square bg-PTSBackgroundDark'
                onClick={() => setSelectedActivity(activity)}>
                <div className="col-start-1 col-span-full flex justify-center items-center">
                    {activity.Name}
                </div>
                <div className='col-start-2 col-span-full flex justify-end items-end'>
                    {activity.NumberAchieved} / {activity.TotalAchievements}
                </div>
            </div>
        ))}
      </>
    ) : (
        <>
        <div className='col-span-full border border-PTSAmber p-2 rounded text-lg text-center font-inter text-PTSBackgroundLight flex justify-center items-center bg-PTSBackgroundDark'>
            GROUPS
        </div>
        {filteredItems.map((group, index) => (
        <div
          key={index}
          className="border border-PTSAmber p-2 rounded cursor-pointer text-xs text-center font-inter text-PTSBackgroundLight flex justify-center items-center aspect-square bg-PTSBackgroundDark"
          onClick={() => setSelectedGroup(group)}>
            {group.Name}
        </div>
         ))}
        </>
    )}
  </div>

  );
}

export default AchievementGridV2;