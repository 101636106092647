import { CloudArrowUpIcon } from '@heroicons/react/20/solid'
import { formatDate } from '../../functionality/dateFunctions'
import { useState } from 'react'

//Contact Cards
//https://tailwindui.com/components/application-ui/lists/grid-lists#component-5d72b187beb968e12db89fe49f272b18

function ApprovalGrid({items,handleApprove,handleReject, handleViewEvidence}) {
  
  const [approvalComment, setApprovalComment] = useState('')
  
  const handleChange = (event) => {
    setApprovalComment(event.target.value); 
  };

  return (   

    <div className='grid grid-cols-2 gap-x-2 gap-y-6 p-1
    sm:grid-cols-3
    lg:grid-cols-4'>
      
      {items.length === 0 ? (
          <div className='col-start-1 col-span-2 p-5 text-xl text-center border border-PTSBackgroundLight text-PTSAmber 
            sm:col-span-3 sm:text-3xl
            lg:col-span-4 lg:text-5xl'>
            No Waiting Approvals
          </div>
      ):(     
        items.map((Approval)=>(  
          <div key={`${Approval.UserID}_${Approval.AchievementID}`} className=''>        
              <div className='grid grid-cols-5 border border-PTSBackgroundLight'>
                <div className='col-start-1 col-span-6 text-PTSAmber'>
                  <div className=''>
                    <p className=' col-start-1 col-span-3'>{Approval.UserName}</p>
                    <p className=' col-start-1 col-span-3'>{Approval.GroupName}</p>
                    <p>{Approval.ActivityName}</p>
                    <p>{Approval.AchievementName}</p>
                    <p>{formatDate(Approval.DateAchieved)}</p>
                  </div>
                </div>

                <button onClick={()=>{ window.open(Approval.AchievementEvidenceURL, '_blank') }} className="col-start-1 col-span-6 font-inter text-base border border-solid h-10 border-white text-PTSBackgroundDark bg-PTSAmber rounded-lg
                  sm:text-lg">
                  View Evidence
                </button>

                <textarea onChange={handleChange} className='col-start-1 col-span-6 h-24 text-left'>

                </textarea>

                {/* <button onClick={()=>handleReject({Approval})} className="z-10 bottom-2 left-0 w-full font-inter border border-solid border-white text-PTSBackgroundDark text-lg bg-PTSAmber rounded-lg"> */}
                <button onClick={()=>{ 
                  const modifiedApproval = {...Approval, ApprovalStatusID: 1, ApprovalComments: approvalComment }
                    handleReject(modifiedApproval)}} 
                  className="z-10 col-span-2 bottom-2 left-0 w-full font-inter text-sm border border-solid border-white text-PTSBackgroundDark bg-PTSAmber rounded-lg
                    sm:text-lg">
                  REJECT
                </button>

                <button onClick={()=>{ 
                  const modifiedApproval = {...Approval, ApprovalStatusID: 2, ApprovalComments: approvalComment }
                    handleApprove(modifiedApproval)}} className="z-10 col-span-3 bottom-2 left-0 w-full font-inter text-sm border border-solid border-white text-PTSBackgroundDark bg-PTSAmber rounded-lg
                      sm:text-lg">
                  APPROVE
                </button>
              </div>          
            </div>
        ))   
      )}
      
    </div>
  )
}

export default ApprovalGrid

